@media(max-width: 1199.98px){
	/* Header css start */
	.navbar-expand-lg .navbar-nav .nav-item .nav-link {
		padding: 10px 7px;
    	font-size: 13px;
	}
	/* Button css start */
	.skillroof-btn{
		font-size: 12px;
	}
	/* Banner css start */
	.banner .swiper-slide img{
		height: 420px;
	}
	/* Adon types css start */
	.adon-types .free-delivery h4{
		font-size: 14px;
	}
	/* login page css start */
	.login_signup_block {
		padding: 0px !important;
	}
}
@media(max-width: 991.98px){
	/* Web Headings css star*/
	.web-heading h2{
		font-size: 27px;
	}
	/* Header css start */
	.mobilecart {
		position: absolute;
		right: 77px;
		font-size: 27px;
		top: 21px;
	}
	
	.mobilecart a {
		color: #fff;
	}
	.navbar-expand-lg .navbar-nav .nav-item .nav-link {
		padding: 7px 7px;
		font-size: 16px;
	}
	a.navbar-brand img {
		width: 120px;
	}
	.navbar-toggler {
		background-color: white;
	}
	.navbar-collapse {
	    position: absolute;
	    top: 124px;
	    background: #fff;
	    width: 100%;
	    z-index: 1000;
	    border-radius: 0px 0px 10px 10px;
	    padding: 25px 10px 0px;
	}
	.search-box {
		width: 100%;
		position: static;
	}
	.navbar .navbar-nav li a i{
		color: #ee7c36;
	}
	.navbar-expand-lg .navbar-nav .nav-item .nav-link{
		color: #000;
	}
	/* Banner css start */
	.banner .swiper-slide img {
		height: 350px;
	}
	/* Download page css start */
	.web-heading{
		margin-bottom: 15px;
	}
	.downloads .skillroof-text h2{
		font-size: 24px;
		padding-bottom: 5px;
	}
	/* feather page css start */
	.features .features-content ul li{
		margin-left: 30px;
	}
	/* Dashboard sidebar css start*/
	.dashboardSidebar{
		position: fixed;
		left: -100%;
	}
	.main{
		margin: 0px;
	}
	.dashboardHeader .navbar{
		padding: 10px 0px;
	}
	.dashboardHeader{
		background-color: #2b2b2b;
	}
	.dashboardHeader span.user_pic_name{
		color: #fff;
	}
	.dashboardHeader .logo img {
		max-width: 80px;
	}
	/* Profile page css atart */
	.profile .profil-img, .profile .profil-img img,
	.edit-profile .profil-img, .edit-profile .profil-img img{
		min-width: 80px;
    	min-height: 80px;
    	width: 80px;
		height: 80px;
	}
	.profile-text{
		width: calc(100% - 80px);
    	padding-left: 15px;
	}
	.profile{
		padding: 10px 22px;
	}
	.profile .profile-info ul li a{
		line-height: 19px;
		display: flex;
       align-items: flex-start;
       word-break: break-all;
	   margin-bottom: 5px;;
	}
	nav.dashboardSidebar.sidebarprofile {
		left: 0px;
	}
	/* My order page css start */
	.ordersPage .order-info .order-action-box .btn-block{
		padding: 5px;
		font-size: 14px;
	}
	.ordersPage .order-info .order-action-box a{
		font-size: 14px;
	}
	.profiletabouter form .btn.skillroof-btn {position: relative;top: -3px;}
	/* schedule-delivery page css atart */
	.schedule-delivery .form-detail{
		padding: 0px;
	}
	.schedule-delivery .img-uploade{
		width: 23%;
		height: 80px;
	}
	/* services page css star */
	.servicesPage{
		padding: 20px 0px;
	}
	
}
@media (max-width:767.98px){
	/* Web Headings css star*/
	.web-heading h2{
		font-size: 22px;
	}
	/* Header css start */
	.mobilecart{
		top:13px;
	}
	a.navbar-brand img {
		width: 90px;
	}
	.search-box .form-group {
		margin-bottom: 5px;
	}
	.navbar-collapse{
		top: 151px;
	}
	/* Banner css start */
	.banner .swiper-slide img {
		height: 250px;
	}
	/* store css start */
	.boxshedowbox h2{
		font-size: 18px;
	}
	.viewall, .categoriesslider .view a{
		padding: 4px 12px;
	}
	/* Adon types css start*/
	.adon-types .free-delivery{
		margin-bottom: 40px;
	}
	/* Contact us section css start */
	.contact-us .contact-left{
		padding: 25px 0;
	}
	.contact-us h3{
		padding-top: 0px;
	}
	.contact-us h2{
		font-size: 30px;
	}
	.contact-us h3{
		font-size: 24px;
	}
	.contact form.login_signup_form{
		padding: 10px 0px;
	}
	/* Home screen css start */
	.product-details p{
		font-size: 14px;
	}
	/* Footer css start */
	.footer .footer-contact h4, .footer .footer-contactul h4{
		font-size: 22px;
		padding-bottom: 10px;
	}
	.footer-contactul {
		margin-top: 40px;
	}
	.copy-right ul.paymentopction{
		justify-content: center;
	}
	.copy-right{
		text-align: center;
	}
	/* Product details page css start */
	.productDetails span.pricetemp{
		margin-right: 15px;
	}
	.productDetails .content-box .offer{
		display: inline-block;
		width: auto;
		margin-left: 0px;;
	}
	/* Cart page css start */
	.add-info-box .d-flex.justify-content-between {
		display: block !important;
	}
	.input-group{
		width: 100%;
	}
	.cartPage .input-group+.input-group{
		margin-right: 10px;
	}
	.cartPage .input-group+.input-group, .price {
		display: inline-block;
		width: auto;
	}
	/* services page css start */
	.servicesPage .services-listing .service-item-box .service-img img{
		width: 50px;
    	height: 50px;
		margin-right: 5px;
	}

	.servicesPage .services-listing .service-item-box span.img-count {
		padding: 0px 4px;
		font-size: 12px;
		left: 28px;
    	top: 56px;
	}
	
	/* services details page css start */
	.serviceDetails .services-listing .service-item-box .service-img img{
		width: 50px;
    	height: 50px;
		margin-right: 5px;
	}
	
	.timing-text {
		width: calc(100% - 60px);
		float: left;
	}

}
@media(max-width:575.98px){
	.socialbtn {
		display: block !important;
	}
	
	.socialbtn button {
		width: 100% !important;
		max-width: 100%;
		margin-bottom: 5px;
	}

	/*  Download Section css start */
	.downloads .skillroof-text ul li{
		width: 150px;
	}
	/* My order page css start */
	.order-action-box {
		justify-content: space-between;
	}
	
	.order-action-box a {
		margin: 0px !important;
	}
	.order_tabs .nav {
		flex-wrap: nowrap;
		width: 100%;
	}
	.order_tabs .nav li.nav-item {
		width: 100%;
	}
	.order_tabs .nav li.nav-item a {
		padding-left: 5px;
		padding-right: 5px;
		width: 100%;
	}
	.ordersPage .order_tabs {
		width: 100%;
	}
	.profile {
		padding: 10px 10px;
	}
	/* business type page css start*/
	.businesstype .item-img-box img{
		height: 140px;
		object-fit: fill;
	}
	.businesstype .item-box {
		padding-bottom: 5px;
	}
	.businesstype .item-box a{
		display: block;
    	min-height: 30px;
	}
	.business-slider .catalog-img img{
		height: 140px;
	}
	/* categories page css start */
	.top-stores li{
		width: 100px;
		margin-right: 5px;
		float: left;
	}
	.top-stores li a .select-img img{
		width: 90px;
		height: 90px;
	}
	/* Edit profile css start */
	.profiletabouter.d-flex {
		display: block !important;
	}
	.edit-profile{
		padding: 20px 15px;
	}
	.profile-text {
		width: 100%;
		padding: 0px;
		margin-top: 15px;
	}
	/* orders page css start */
	.ordersPage .order-info .order-action-box .btn-block, .order-status {
		padding: 5px 7px !important;
		font-size: 14px !important;
		width: auto !important;
		margin: 0px 2px !important;
	}
	.ordersPage .order-info .number-wrap li{
		padding-left: 5px;
		padding-right: 5px;
	}
	li.delivery-date {
		display: flex;
		white-space: nowrap;
		margin-left: -52px;
	}
	/* Cart page css start */
	.cartPage .cart-item-box {
		width: 50px;
	}
	.cartPage .add-info-box {
		width: calc(100% - 50px);
		padding-left: 15px;
	}
	.add-info-box a.skillroof-btn.btn-red {
		margin-left: 10px;
	}
	/* services details page css start */
	.confirm-delivery{
		margin-top: 15px;
	}

	.serviceDetails .services-listing .timing-text ul li{
		width: 20px;
		height: 20px;
		font-size: 10px;
		margin: 3px 1px 0px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding-top: 2px;
	}
	.timing-text ul {
		display: flex;
		font-size: 14px;
		align-items: center;
		margin-bottom: 6px;
	}
	.servicesPage .services-listing .timing-text ul li{
		width: 20px;
		height: 20px;
		font-size: 10px;
		margin: 3px 1px 0px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding-top: 2px;
	}
	.timing-text ul {
		display: flex;
		font-size: 14px;
		align-items: center;
		margin-bottom: 6px;
	}
	.serviceDetails .services-listing .timing-text ul li{
		width: 20px;
		height: 20px;
		font-size: 10px;
		margin: 3px 1px 0px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding-top: 2px;
	}
	.timing-text ul {
		display: flex;
		font-size: 14px;
		align-items: center;
		margin-bottom: 6px;
	}
}