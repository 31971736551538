/********** Global Body Elements **********/
html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif !important;
}

ul,
ol {
	padding: 0px;
	margin: 0px;
}

ul li {
	padding: 0;
	margin: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
}

p {
	padding: 0;
	margin: 0;
}

a {
	padding: 0;
	margin: 0;
	text-decoration: none !important;
	-webkit-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

button,
a {
	box-shadow: none !important;
	outline: none !important;
	-webkit-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

input,
textarea {
	box-shadow: none !important;
	outline: none !important;
	border: 1px solid #ccc;
}

/********** Global Formik CSS **********/

.invalid-feedback {
	font-size: 14px;
}

/********** Global Button CSS Classes **********/

.navodaya-app-btn {
	color: #fff;
	background-color: #6ab344 !important;
	border: 1px solid #6ab344 !important;
	border-radius: 4px;
	font-size: 16px;
	padding: 7px 15px;
	text-transform: uppercase;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	transition: all 0.5s ease-out;
}

.navodaya-app-btn:hover {
	background-color: transparent !important;
	color: #6ab344 !important;
}

.btn-green {
	background-color: #2eb532 !important;
	border: 1px solid #2eb532 !important;
}

.btn-green:hover {
	background-color: #fff !important;
	color: #2eb532 !important;
	border: 1px solid #2eb532 !important;
}

.btn-red {
	background-color: #ff0000 !important;
	border: 1px solid #ff0000 !important;
}

.btn-red:hover {
	background-color: #fff !important;
	color: #ff0000 !important;
	border: 1px solid #ff0000 !important;
}

.btn-blue {
	background-color: #007bff !important;
	border: 1px solid #007bff !important;
}

.btn-blue:hover {
	background-color: #fff !important;
	color: #007bff !important;
	border: 1px solid #007bff !important;
}

.btn-yellow {
	background-color: #ffc107 !important;
	border: 1px solid #ffc107 !important;
}

.btn-yellow:hover {
	background-color: #fff !important;
	color: #ffc107 !important;
	border: 1px solid #ffc107 !important;
}

.btn-disbaled {
	opacity: 0.4 !important;
	cursor: not-allowed !important;
}

/********** Global Color CSS Classes **********/

.color-red {
	color: #ff0000 !important;
}

.color-orange {
	color: #6ab344 !important;
}

.color-yellow {
	color: #ffc107 !important;
}

.color-blue {
	color: #007bff !important;
}

.color-green {
	color: #2eb532 !important;
}

/********** Global Font CSS Classes **********/

.react-confirm-alert h1 {
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: bold;
}

.font-bold {
	font-weight: bold !important;
}

/********** Global Swiper Slider CSS **********/

.swiper-container {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	background-position: center;
	background-size: cover;
}

.swiper-slide img {
	width: 100%;
	height: auto;
}

.swiper-button-prev,
.swiper-button-next {
	top: 50%;
}

.swiper-button-prev,
.swiper-button-next {
	color: #ffffff !important;
	transform: translateY(-50%);
	background: #5ba5fb;
	padding: 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none !important;
	box-shadow: none !important;
	opacity: 1 !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: 0px;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	right: 0px;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
	font-size: 20px;
}

.banner .swiper-button-prev,
.banner .swiper-button-next {
	top: 50%;
	transform: translateY(-30%) !important;
}

/********** Main Layout Header CSS **********/

header {
	background-color: #fff;
	padding: 10px 0;
}

.navbar {
	padding: 0;
}

.search-box .form-group {
	margin: 0px;
}

.navbar-brand {
	margin: 0px;
	width: 200px;
}

.navbar-brand img {
	width: 300px;
}

.navbar-expand-lg .navbar-nav .nav-item .nav-link {
	color: #343a40;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 19px;
	position: relative;
	text-transform: uppercase;
}

.navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link {
	padding-right: 0px;
}

.navbar-nav .nav-item .nav-link:hover,
.navbar-nav .nav-item.active .nav-link {
	color: #6ab344;
}

.navbar .navbar-nav li a i {
	padding: 11px 10px 0;
	font-size: 20px;
	color: #fff;
}

.navbar .navbar-nav li .baged {
	position: absolute;
	top: -10px;
	right: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 17px;
	height: 17px;
	line-height: 12px;
	text-align: center;
	color: #fff;
	font-size: 11px;
	border-radius: 100%;
	background-color: #6ab344;
	border: 0px solid #fff;
	content: "";
	z-index: 1;
}

/* Sub Menu */
.submenu {
	display: none;
	position: absolute;
	z-index: 100;
	background: #fff;
	border-radius: 5px;
	width: 170px;
}

.submenu li:first-child {
	border-radius: 5px 5px 0px 0px;
	overflow: hidden;
}

.submenu li:last-child {
	border-radius: 0px 0px 5px 5px;
	overflow: hidden;
}

.submenu li a {
	padding: 5px 10px !important;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid #eaeaea;
	color: #000;
	text-align: left;
	font-size: 14px;
	cursor: pointer;
}

.submenu li a.active,
.submenu li a:hover {
	background: #6ab344;
	color: #fff;
}

.submenu li:last-child a {
	border: 0px;
}

ul.submenu::after {
	content: "";
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	border-top: 0.8em solid;
	border-right: 0.8em solid transparent;
	border-bottom: 0;
	border-left: 0.8em solid transparent;
	position: absolute;
	top: -12px;
	color: #fff;
	transform: rotate(180deg);
	left: 13px;
}


/********** Main Layout Footer CSS **********/

.footer {
	padding: 30px 0 20px;
	background: #000;
}

.footer .footer-contact h4,
.footer .footer-contactul h4 {
	font-size: 24px;
	color: #fff;
	font-weight: 500;
	padding-bottom: 20px;
}

.footer-contactul ul li a:hover {
	color: #6ab344;
}

.footer .footer-contact ul li a {
	text-decoration: none;
	font-size: 15px;
	font-weight: 400;
	color: #fff !important;
	padding: 4px 0;
	display: block;
	padding-left: 24px;
	position: relative;
	word-wrap: break-word;
}

.footer .footer-contact ul li a i {
	color: #fff !important;
	font-size: 14px;
	padding-right: 10px;
	position: absolute;
	left: 0px;
	top: 7px;
}

.footer .follow ul li {
	display: inline-block;
	text-align: center;
	margin-right: 20px;
}

.footer .follow ul li img {
	border-radius: 10%;
	width: 40px;
	height: 40px;
}

.footer .follow ul li a i {
	color: #fff;
	text-align: center;
	padding: 6px 0;
}

.footer-contactul ul li a {
	color: #fff;
	display: block;
	font-size: 15px;
	padding: 1px 0px;
}

.copy-right {
	background-color: #191919;
	padding: 10px 0;
	text-align: center;
}

.copy-right p {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}

.copy-right ul.paymentopction {
	display: flex;
	justify-content: flex-end;
}

.copy-right ul.paymentopction a {
	padding: 0px 0px 0px 5px;
}

.copy-right ul.paymentopction a img {
	border-radius: 2px;
}

/*************** Page Heading CSS ****************/

.web-heading {
	margin-bottom: 20px;
}

.web-heading h2 {
	font-size: 2.25rem;
	color: #6ab344;
	font-weight: 600;
	text-transform: uppercase;
}

/*************** Home Page CSS ****************/

/* Banner Section */
.banner .swiper-slide img {
	height: 610px;
	width: 100%;
	object-fit: contain;
}

/* About Us Section */

.about-us {
	background-color: #dee2e6;
	padding: 50px 0;
}

.about-us ul {
	padding-left: 20px;
	padding-bottom: 10px;
}

.about-us ul li {
	list-style: circle;
	text-align: justify;
	line-height: 25px;
}

.about-us p {
	font-size: 16px;
	color: #3c3c3c;
	line-height: 20px;
	font-weight: 500;
	padding-bottom: 10px;
	text-align: justify;
	line-height: 23px;
}

.about-us .embed-responsive {
	width: 100%;
	margin-top: 20px;
}

.about-us .provider {
	text-align: center;
	font-size: 16px;
	color: #333;
	padding-top: 15px;
}

/* Downloads Section */

.downloads {
	background-color: #5ba5fbeb;
	padding: 50px 0 0;
}

.downloads .navodaya-app-text h2 {
	font-size: 2.25rem;
	color: #fff;
	font-weight: 600;
	padding-bottom: 20px;
}

.downloads .navodaya-app-text h3 {
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	;
}

.downloads .navodaya-app-text ul {
	margin: 40px 0;
}

.downloads .navodaya-app-text ul li {
	display: inline-block;
	margin-right: 20px;
}

.downloads .navodaya-app-text ul li a img {
	border-radius: 63px;
	background-color: #000;
}

.downloads .mobile-img img {
	width: 100%;
}

/* Overviews Section */

.overview {
	background-color: #ff000061;
	padding: 50px 0;
}

.overview-slider {
	margin: 50px 0px;
}

.overview .swiper-slide img {
	height: auto;
	width: 100%;
	object-fit: cover;
	object-position: top;
}

/*  Features Section */

.features {
	padding: 30px 0;
}

.feature_row {
	display: flex;
	align-items: center;
	margin: 30px 0px;
}

.features .features-img img {
	width: 87%;
}

.features-img {
	width: 100%;
	height: auto;
	object-fit: contain;
}

.features .features-content ul li {
	margin-left: 25%;
	margin-bottom: 20px;
	margin-top: 20px;
	font-size: 20px;
	color: rgb(60, 60, 60);
	font-style: normal;
	font-weight: 600;
	list-style: circle;
}

/* Contact Us Section */
.contact-us .contact-left {
	background-color: #6ab344bf;
	padding: 108px 0;
}

.contact-us h2 {
	color: #333333;
	font-size: 45px;
	text-align: center;
	font-weight: 600;
}

.contact-us h3 {
	color: #fff;
	font-size: 32px;
	text-align: center;
	font-weight: 500;
	padding: 50px 0 0;
}


.contact-us h4 {
	font-size: 26px;
	color: #333333;
	font-weight: 600;
	padding: 30px 0 15px;
}

.contact-us .form-control {
	display: block;
	width: 100%;
	padding: 20px;
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ccc;
	font-weight: 500;
}

.contact-us .form-group {
	margin-bottom: 21px;
}

.contact-us .contact-right {
	padding-bottom: 20px;
}

/*********** Privacy Policy & Tems Conditions Page **********/

.terms-conditions,
.privacy-policy {
	padding: 30px 0;
}

.terms-conditions-content,
.privacy-policy-content {
	margin: 30px 0px;
}

.terms-conditions ul,
.privacy-policy ul {
	padding-left: 20px;
	padding-bottom: 10px;
}

.terms-conditions ul li,
.privacy-policy ul li {
	list-style: circle;
	text-align: justify;
	line-height: 25px;
}

.terms-conditions p,
.privacy-policy p {
	font-size: 16px;
	color: #3c3c3c;
	line-height: 20px;
	font-weight: 500;
	padding-bottom: 10px;
}

.terms-conditions .embed-responsive,
.privacy-policy .embed-responsive {
	width: 100%;
	margin-top: 20px;
}

.terms-conditions .provider,
.privacy-policy .provider {
	text-align: center;
	font-size: 16px;
	color: #333;
	padding-top: 15px;
}


/*********** FAQs Page **********/

.faqs {
	padding: 30px 0;
}

.faqs ol,
.faqs ul {
	padding-left: 30px;
	padding-bottom: 10px;
}

.faqs ul li {
	list-style: circle;
	text-align: justify;
	line-height: 25px;
}